<template>
  <b-sidebar
    id="edit-video-sidebar"
    :visible="isEditVideoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-video-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Video</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div class="d-flex w-100">
            <b-form-group class="w-100" label="Video Title">
              <b-form-input
                v-model="videoDetails.title"
                placeholder="Enter video title.."
                type="text"
              />
            </b-form-group>
          </div>
          <div style="display: flex; flex-direction: row; overflow: auto">
            <span
              style="width: 70px; height: auto; position: relative"
              v-if="videoDetails.video_tumbnail != ''"
            >
              <img
                style="width: 200px; height: 120px"
                :src="videoDetails.video_tumbnail"
              />
            </span>
          </div>
          <br />
          <b-button variant="primary" @click="$refs.refInputE11.click()">
            <input
              ref="refInputE11"
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              class="d-none"
              @input="imageThumbnailUpload"
            />
            <span class="d-none d-sm-inline">Upload Thumbnail</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
          <br />
          <br />

          <div style="display: flex; flex-direction: row; overflow: auto">
            <span
              style="width: 200px; height: auto; position: relative"
              v-if="
                videoDetails.video_uri !=
                  'https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png' &&
                videoDetails.video_uri !=
                  'https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif'
              "
            >
              <b-embed
                type="video"
                aspect="4by3"
                controls
                :poster="videoDetails.video_tumbnail"
              >
                <source :src="videoDetails.video_uri" type="video/mp4" />
              </b-embed>
            </span>
            <span style="width: 200px; height: auto; position: relative" v-else>
              <img
                style="width: 200px; height: 120px"
                :src="videoDetails.video_uri"
                alt=""
              />
            </span>
          </div>
          <br />
          <b-button variant="primary" @click="$refs.refInputE2.click()">
            <input
              ref="refInputE2"
              type="file"
              accept="video/mp4"
              class="d-none"
              @input="videoUpload"
            />
            <span class="d-none d-sm-inline">Upload Video</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
          <br />
          <div
            class="d-flex mt-2"
            style="position: absolute; bottom: 3%; left: 3%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="hide"
              :disabled="submitbutton"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
    
    <script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCol,
  BEmbed,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../@fake-db/data/other/countries";
import store from "@/store";
import JsonWebTokenError from "jsonwebtoken/lib/JsonWebTokenError";
import axios from "axios";
import S3 from "aws-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Uploader from "../awsImageUpload.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BEmbed,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditVideoSidebarActive",
    event: "update:is-edit-video-sidebar-active",
  },
  props: {
    isEditVideoSidebarActive: {
      type: Boolean,
      required: true,
    },
    editVideoObj: {
      type: Object,
      required: true,
    },
  },
  watch: {
    editVideoObj() {
      // console.log('editVideoObj',editVideoObj);
      //   console.log("editVideoObj", this.editVideoObj.title);
      this.videoDetails.title = this.editVideoObj.title;
      this.videoDetails.video_tumbnail = this.editVideoObj.videoThumbnail;
      this.videoDetails.video_uri = this.editVideoObj.video_uri;
    },
  },
  data() {
    return {
      videoDetails: {
        title: "",
        video_tumbnail:
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.61587843675732310.167758814713014460.38897381201168635.gif",
        video_uri:
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png",
      },
      toastMessage: "",
      image_Name: {},
      video_Name: {},
      OldUrl: [],
      old_video: false,
      old_video_thumbnail: false,
      newUrl: [],
    };
  },
  computed: {
    submitbutton() {
      return !(
        this.videoDetails.title.length > 0 &&
        this.videoDetails.video_tumbnail.length > 0 &&
        this.videoDetails.video_uri.length > 0 &&
        this.videoDetails.video_uri !=
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png" &&
        this.videoDetails.video_tumbnail !=
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.61587843675732310.167758814713014460.38897381201168635.gif" &&
        this.videoDetails.video_uri !=
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif" &&
        this.videoDetails.video_tumbnail !=
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif"
      );
    },
  },
  created() {},
  methods: {
    resetForm() {
      // this.videoDetails = {
      //   title: "",
      //   video_tumbnail:
      //     "https://zape02.s3.ap-southeast-1.amazonaws.com/0.61587843675732310.167758814713014460.38897381201168635.gif",
      //   video_uri:
      //     "https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png",
      // };
    },
    onSubmit() {
      // console.log("this.videoDetails.video_tumbnail", this.videoDetails);
      // console.log("this.old_video_thumbnail", this.old_video_thumbnail);
      if (this.old_video_thumbnail) {
        this.OldUrl = this.fileNameAndExtWithSlash(
          this.editVideoObj.videoThumbnail
        );
        // console.log("this.OldUrl", this.OldUrl);
        this.newUrl = this.fileNameAndExt(this.image_Name["name"]);
        // console.log('this.OldUrl',this.OldUrl);
        // console.log("this.newUrl", this.newUrl);
        // console.log("this.image_Name", this.image_Name);
        let payload = {
          contentType: this.newUrl[1],
          fileName: this.OldUrl[1],
          file: this.image_Name,
        };
        // console.log("payload", payload);
        new Uploader().uploadImage(payload).then((response) => {
          // console.log("let new s3 path", response.Location);
          this.videoDetails.video_tumbnail = response.Location;
          if (this.old_video) {
            this.OldUrl = this.fileNameAndExtWithSlash(
              this.editVideoObj.video_uri
            );
            this.newUrl = this.fileNameAndExt(this.video_Name["name"]);
            let payload = {
              contentType: this.newUrl[1],
              fileName: this.OldUrl[1],
              file: this.video_Name,
            };
            new Uploader().uploadImage(payload).then((response) => {
              this.videoDetails.video_uri = response.Location;
              axios
                .post(
                  process.env.VUE_APP_API_URL +
                    "/zape/video/edit/" +
                    this.editVideoObj._id,
                  this.videoDetails,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                  }
                )
                .then((response) => {
                  // console.log("response.data", response.data);
                  if (response.data.status) {
                    this.toastMessage = response.data.message;
                    this.showToast("success");
                    this.$emit("clickEditNewVideoSuccess", response.data);
                  } else {
                    this.toastMessage =
                      "There are some problem with the video try again.";
                    this.showToast("danger");
                  }
                });
              // console.log("res", response);
            });
          } else {
            axios
              .post(
                process.env.VUE_APP_API_URL +
                  "/zape/video/edit/" +
                  this.editVideoObj._id,
                this.videoDetails,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("_t"),
                  },
                }
              )
              .then((response) => {
                // console.log("response.data", response.data);
                if (response.data.status) {
                  this.toastMessage = response.data.message;
                  this.showToast("success");
                  this.$emit("clickEditNewVideoSuccess", response.data);
                } else {
                  this.toastMessage =
                    "There are some problem with the video try again.";
                  this.showToast("danger");
                }
              });
          }
          // console.log("res", response);
        });
      } else {
        if (this.old_video) {
          this.OldUrl = this.fileNameAndExtWithSlash(
            this.editVideoObj.video_uri
          );
          this.newUrl = this.fileNameAndExt(this.video_Name["name"]);
          let payload = {
            contentType: this.newUrl[1],
            fileName: this.OldUrl[1],
            file: this.video_Name,
          };
          new Uploader().uploadImage(payload).then((response) => {
            this.videoDetails.video_uri = response.Location;
            axios
              .post(
                process.env.VUE_APP_API_URL +
                  "/zape/video/edit/" +
                  this.editVideoObj._id,
                this.videoDetails,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("_t"),
                  },
                }
              )
              .then((response) => {
                // console.log("response.data", response.data);
                if (response.data.status) {
                  this.toastMessage = response.data.message;
                  this.showToast("success");
                  this.$emit("clickEditNewVideoSuccess", response.data);
                } else {
                  this.toastMessage =
                    "There are some problem with the video try again.";
                  this.showToast("danger");
                }
              });
            // console.log("res", response);
          });
        } else {
          axios
            .post(
              process.env.VUE_APP_API_URL +
                "/zape/video/edit/" +
                this.editVideoObj._id,
              this.videoDetails,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              // console.log("response.data", response.data);
              if (response.data.status) {
                this.toastMessage = response.data.message;
                this.showToast("success");
                this.$emit("clickEditNewVideoSuccess", response.data);
              } else {
                this.toastMessage =
                  "There are some problem with the video try again.";
                this.showToast("danger");
              }
            });
        }
      }
      // console.log("this.videoDetails.video_tumbnail", this.videoDetails);
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.toastMessage,
          variant,
        },
      });
    },
    fileNameAndExt(str) {
      // console.log("str-------", str);
      var file = str.split("/").pop();
      return [
        file.substr(0, file.lastIndexOf(".")),
        file.substr(file.lastIndexOf(".") + 1, file.length),
      ];
    },
    fileNameAndExtWithSlash(str) {
      // console.log("str-------", str);
      var file = str.split("/").pop();
      return [
        file.substr(0, file.lastIndexOf("/")),
        file.substr(file.lastIndexOf("/") + 1, file.length),
      ];
    },
    imageThumbnailUpload(data) {
      if (data.target.files && data.target.files[0]) {
        this.videoDetails.video_tumbnail =
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif";
        this.image_Name = data.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.old_video_thumbnail = true;
          this.videoDetails.video_tumbnail = e.target.result;
        };
        reader.readAsDataURL(data.target.files[0]);
      }
    },

    videoUpload(data) {
      if (data.target.files && data.target.files[0]) {
        this.videoDetails.video_uri =
          "https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif";
        this.video_Name = data.target.files[0];
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            // console.log("data", data);
            this.old_video = true;
            this.videoDetails.video_uri = data.location;
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>
    
    <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
    